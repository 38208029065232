import {
  Box,
  Button,
  Heading,
  Highlight,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { Link } from "react-router-dom"

import { colors } from "@bleu/front/assets/theme/colors"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { RecordsHistory } from "@bleu/front/components/patientSpace/RecordsHistory"
import { StepsCardsList } from "@bleu/front/components/patientSpace/StepCardsList"
import { VerticalsCards } from "@bleu/front/components/patientSpace/VerticalsCards"
import { useAccountPageQuery } from "@bleu/front/queries/account"
import { useAuthState } from "@bleu/front/queries/auth"
import { amplitudeTrack } from "@bleu/front/utils/tracking"

export const PatientHomePage = () => {
  const { isSignedIn, isLoading, user } = useAuthState()

  useEffect(() => {
    amplitudeTrack("Patient Home Viewed", {})
  }, [])

  return (
    <Box>
      <HStack justify="space-between">
        <Heading size="3xl">
          {!isLoading && (
            <Highlight
              query={isSignedIn ? user.firstName : "Bleu"}
              // TODO: use colors from styles function prop
              styles={{ color: colors.blue[400] }}
            >
              {isSignedIn
                ? `Bienvenue, ${user.firstName} !`
                : "Bienvenue sur Bleu"}
            </Highlight>
          )}
        </Heading>
        <Button as={Link} to="/questionnaire" display={["none", "flex"]}>
          Faire un nouveau dépistage
        </Button>
      </HStack>
      <VSpacer size={3} />

      {isSignedIn && (
        <>
          <Text color="navy.300">
            Dans votre espace patient : ordonnances, messagerie sécurisée et
            informations de compte.
          </Text>
          <VSpacer size={8} />
          <Heading size="2xl">Mes ordonnances</Heading>
          <VSpacer size={6} />
          <PatientMedicalRecordsHistory />
        </>
      )}

      <VSpacer size={8} />
      <Heading size="2xl">Dépistages disponibles</Heading>
      <VSpacer size={6} />
      <VerticalsCards />
      <VSpacer size={8} />
      <Heading size="2xl">Comment ça marche</Heading>
      <VSpacer size={6} />
      <StepsCardsList />
      <VStack
        display={["flex", "none"]}
        alignItems="stretch"
        position="sticky"
        bottom={10}
      >
        <VSpacer size={20} />
        <Button as={Link} to="/questionnaire">
          Nouveau dépistage
        </Button>
      </VStack>
    </Box>
  )
}

const PatientMedicalRecordsHistory = () => {
  // TODO: weird naming
  const accountQuery = useAccountPageQuery()

  return (
    <QueryWrapper query={accountQuery}>
      {({ data }) => <RecordsHistory records={data.recordsHistory} />}
    </QueryWrapper>
  )
}
