import { ChakraBaseProvider } from "@chakra-ui/react"
import {
  ClerkLoaded,
  ClerkLoading,
  ClerkProvider,
  useUser,
} from "@clerk/clerk-react"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import dayjs from "dayjs"
import "dayjs/locale/fr"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import { PostHogProvider, usePostHog } from "posthog-js/react"
import { useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { RouterProvider } from "react-router-dom"

import { theme } from "@bleu/front/assets/theme"
import { CLERK_COPY, CLERK_THEME } from "@bleu/front/assets/theme/clerk"
import { FullPageLoader } from "@bleu/front/components/layout/FullPageLoader"
import { queryClient } from "@bleu/front/lib/queryClient"
import { router } from "@bleu/front/pages/router"

import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback"
import "./global.css"
import { initFacebookPixel } from "./utils/facebookPixel"

dayjs.extend(LocalizedFormat)
dayjs.locale("fr")

const CLERK_PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
const POSTHOG_KEY = import.meta.env.VITE_POSTHOG_KEY
const POSTHOG_HOST = import.meta.env.VITE_POSTHOG_HOST
const VITE_API_URL = import.meta.env.VITE_API_URL

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk Publishable Key")
}

if (!POSTHOG_KEY || !POSTHOG_HOST) {
  throw new Error("Missing PostHog configuration")
}

// Define the default PostHog endpoint
const DEFAULT_POSTHOG_ENDPOINT = "https://us.i.posthog.com"

// Function to determine the appropriate PostHog endpoint
function getPostHogEndpoint() {
  if (VITE_API_URL) {
    return `${VITE_API_URL}/collect`
  }
  console.warn("VITE_API_URL is not set. Using default PostHog endpoint.")
  return DEFAULT_POSTHOG_ENDPOINT
}

/**
 * Component to initialize Facebook Pixel and PostHog with user data.
 */
function UserDataInitializer() {
  const { user, isLoaded } = useUser()
  const posthog = usePostHog()

  useEffect(() => {
    if (isLoaded && user) {
      // Initialize Facebook Pixel
      initFacebookPixel(user.primaryEmailAddress?.emailAddress)

      // Initialize PostHog
      if (posthog && !posthog._isIdentified()) {
        posthog?.identify(user.id, {
          email: user.primaryEmailAddress?.emailAddress,
          name: `${user.firstName} ${user.lastName}`,
          id: user.id,
        })
      }
    }

    // Reset PostHog identification if user is not available
    if (isLoaded && !user && posthog?._isIdentified()) {
      posthog?.reset()
    }
  }, [isLoaded, user, posthog])

  return null
}

function App() {
  return (
    <ClerkProvider
      localization={CLERK_COPY}
      appearance={CLERK_THEME}
      publishableKey={CLERK_PUBLISHABLE_KEY}
    >
      <PostHogProvider
        apiKey={POSTHOG_KEY}
        options={{ api_host: getPostHogEndpoint() }}
      >
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <ChakraBaseProvider theme={theme}>
            <ClerkLoaded>
              <QueryClientProvider client={queryClient}>
                <UserDataInitializer />
                <RouterProvider router={router} />
                <ReactQueryDevtools />
              </QueryClientProvider>
            </ClerkLoaded>
            <ClerkLoading>
              <FullPageLoader />
            </ClerkLoading>
          </ChakraBaseProvider>
        </ErrorBoundary>
      </PostHogProvider>
    </ClerkProvider>
  )
}

export default App
