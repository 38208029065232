import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react"
import { XCircleIcon } from "@heroicons/react/24/solid"
import { useMutation } from "@tanstack/react-query"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { apiClient } from "@bleu/front/lib/apiClient"
import { useVerticalsStore } from "@bleu/front/lib/stores"
import { logger } from "@bleu/front/utils/logger"
import { amplitudeTrack, gtmTrack } from "@bleu/front/utils/tracking"

export function PaymentFailed() {
  const [searchParams] = useSearchParams()
  const { selectedVerticals } = useVerticalsStore()
  const medicalRecordCode = searchParams.get("r")

  useEffect(() => {
    const error = searchParams.get("error")
    if (error) {
      logger.warn("Payment failed, tracking event", {
        error,
        medicalRecordCode,
      })
      amplitudeTrack("Payment Failed", { error, medicalRecordCode })
      gtmTrack("payment_failed", { error, medicalRecordCode })
    } else {
      logger.warn("No error parameter found in search params")
    }
  }, [searchParams, medicalRecordCode])

  const { mutate: retryPayment, isPending } = useMutation({
    mutationFn: async () => {
      logger.info("Retrying payment, creating new checkout session")

      if (!medicalRecordCode || selectedVerticals.length === 0) {
        throw new Error("Missing medicalRecordCode or vertical in store")
      }

      const response = await apiClient.post<{ url: string }>(
        "/stripe/create-checkout-session",
        { medicalRecordCode, vertical: selectedVerticals[0] },
      )
      return response.data
    },
    onSuccess: (data) => {
      logger.info("New checkout session created, redirecting")
      window.location.href = data.url
    },
    onError: (error) => {
      logger.error("Error creating new checkout session", { error })
      amplitudeTrack("Payment Retry Failed", { error })
      gtmTrack("payment_failed", { error })
    },
  })

  function handleRetry() {
    logger.info("User initiated payment retry")
    retryPayment()
  }

  logger.info("Rendering PaymentFailed component content")
  return (
    <Box
      maxWidth="600px"
      margin="auto"
      padding={8}
      bg="white"
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
    >
      <VStack spacing={8} align="center">
        <XCircleIcon className="h-24 w-24 text-red-500" />
        <Heading as="h1" size="xl" textAlign="center">
          Échec du paiement
        </Heading>
        <VStack spacing={4} align="center">
          <Text fontSize="lg" textAlign="center">
            Nous sommes désolés, mais votre paiement n&apos;a pas pu être
            traité.
          </Text>
          <Text fontSize="lg" textAlign="center">
            Veuillez réessayer ou contacter notre support si le problème
            persiste.
          </Text>
        </VStack>
        <Button
          onClick={handleRetry}
          colorScheme="blue"
          size="lg"
          isLoading={isPending}
          loadingText="Préparation..."
        >
          Réessayer le paiement
        </Button>
      </VStack>
    </Box>
  )
}
